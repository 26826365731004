import { Address } from 'viem'

export enum RewardToken {
  LYRA = 'LYRA',
  USDC = 'USDC',
  OP = 'OP',
}

export type RewardTokenConfig = {
  [key in RewardToken]: {
    isDollarFormat: boolean
    tokenName: string
  }
}

export type RewardEventType =
  | 'trading'
  | 'frame-dice-roll'
  | 'frame-pfp'
  | 'affiliate'
  | 'alpha-trading'
  | 'custom'

type TradingEventFields = {
  type: 'trading'
  endsAt: number
  fees: number
  totalFees: number
  week: number
  projectedTotalFees: number
  projectedRewards: number
}

type AlphaTradingEventFields = {
  type: 'alpha-trading'
  endsAt: number
  fees: number
  week: number
  totalVolume: number
  notionalVolumeToPremiumRatio: number
  totalOptionsVolume?: number
  totalPerpsVolume?: number
  totalOptionsFees?: number
  totalPerpsFees?: number
  overallRank?: number
  tierRank?: number
  tier?: number | string
}

type FrameDiceRollFields = {
  type: 'frame-dice-roll'
  username: string
  fid: number
  score: number
}

type FramePfpFields = {
  type: 'frame-pfp'
  username: string
  fid: number
}

type AffiliateFields = {
  type: 'affiliate'
  month: number
  fees: number
  walletIds: string[]
  tradeIds: string[]
  totalOptionsVolume?: number
  totalPerpsVolume?: number
}

type CustomFields = {
  type: 'custom'
}

export type RewardEvent = {
  address: Address
  type: RewardEventType
  event: string
  rewards: number
  token: RewardToken
  createdAt: number
  updatedAt: number
  distributedAt: number
} & (
  | TradingEventFields
  | FrameDiceRollFields
  | FramePfpFields
  | AffiliateFields
  | AlphaTradingEventFields
  | CustomFields
)

export type TradingRewardEvent = RewardEvent & TradingEventFields
export type AlphaTradingRewardEvent = RewardEvent & AlphaTradingEventFields
export type AffiliateRewardEvent = RewardEvent & AffiliateFields

// Starting at V2 claim batch IDs
export const DISTRIBUTOR_REWARD_BATCH_IDS = [...Array(500).keys()]
  .map((id) => BigInt(id + 1))
  .slice(243)

// Rewards distributed on Apr 26 for rewards up to (and including) week 11
export const LAST_DISTRIBUTED_REWARDS_WEEK = 11
